import axiosClient from "@/axios";

export const EventService = {
    // TODO: PRIORITY: C
    /* 
    getData() {
        return [
          fetchEvents()
        ];
    },

    // ***HERE WERE ALSO DIFFERENT PAGINATION VARIANTS. ABANDONED DUE TO: not being used yet***

    getEvents() {
        return Promise.resolve(this.getData());
    },*/

    deleteEvent(id) {
        return axiosClient.delete(`/admin/events/${id}`);
    }
};

