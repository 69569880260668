<script setup>
import { computed } from 'vue';
import BaseInput from './BaseInput.vue';
import { useHasAddressErrpr as useHasAddressError } from '@/utils/errors';

// Accepting an additional prop for unique identification
const props = defineProps({
  object_address: {
    type: Object,
  },
  suffix: {
    type: String,
    default: ''
  },
  isRequired: {
    type: Boolean,
    default: false
  }
});

const selectCountryOptions = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'}
];

const computedCountryCode = computed(() => props.object_address.countryCode);

const { hasAddressError } = useHasAddressError();

// console.log("AddressComponent");
</script>

<template>
      <ul v-if="hasAddressError.length" class="text-red-600">
        <p>Zadaná adresa obsahuje chyby, prosím opravte.</p>
        <li v-for="(error, index) in hasAddressError" :key="index">
            {{ error }}
        </li>
    </ul>

    <div>
        <BaseInput
            :label="`Město${props.isRequired ? '*' : ''}`"
            :name="`city_${props.suffix}`"
            v-model="props.object_address.city"
            placeholder="Město"
            :required="props.isRequired"
        />
    </div>

    <div>
        <BaseInput
            :label="`PSČ${props.isRequired ? '*' : ''}`"
            :name="`postal_code_${props.suffix}`"
            v-model="props.object_address.postal_code"
            placeholder="PSČ"
            pattern="[0-9]{5}"
            :required="props.isRequired"
            :maxlength="5"
            :minlength="5"
        />
        <small>Zadejte bez mezery</small>
    </div>

    <div>
        <BaseInput
            :label="`Ulice${props.isRequired ? '*' : ''}`"
            :name="`street_${props.suffix}`"
            v-model="props.object_address.street"
            placeholder="Ulice"
            :required="props.isRequired"
        />
    </div>

    <div>
        <BaseInput
            :label="`Číslo popisné${props.isRequired ? '*' : ''}`"
            :name="`house_number_${props.suffix}`"
            v-model="props.object_address.house_number"
            placeholder="Číslo popisné"
            pattern="[A-Z0-9]+"
            :required="props.isRequired"
        />
    </div>
    <div>
        <BaseInput
            :label="`Číslo orientační`"
            :name="`orientation_number_${props.suffix}`"
            v-model="props.object_address.orientation_number"
            placeholder="Číslo orientační"
            pattern="[a-zA-Z0-9]+"
            :required=false
        />
    </div>
</template>
