<script setup>
import DataTable from 'primevue/datatable';
import AddressComponent from "@/components/AddressComponent.vue";
import { reactive } from "vue";
import axiosClient from "@/axios";

import {onMounted, onUnmounted, watch, ref} from "vue";
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Button from 'primevue/button';
import { computed } from 'vue';
import BaseBtn from "@/components/BaseBtn.vue";
import Dialog from 'primevue/dialog';

// Dialog imports
import Toast from "primevue/toast";
import BaseInput from "@/components/BaseInput.vue";
import { toRaw } from 'vue';
import { useToast } from 'primevue/usetoast';
import { EventService } from '@/services/EventService';

const props = defineProps({
    selectionEnabled: {
        type: Boolean,
        default: false
    }
});

const selectedEventId = ref(null);

// Emit the selected event ID when it changes
const emits = defineEmits(['update:selectedEventId']);
watch(selectedEventId, (newValue) => {
    emits('update:selectedEventId', newValue);
});

const toast = useToast();
const events = ref();
const event = reactive({
    name: '',
    date_from: '',
    date_to: '',
    time_from: '',
    time_to: '',
    status: 'nová',
    event_address: {
        street: '',
        house_number: '',
        city: '',
        postal_code: '',
        country_code: 'CZ',
        orientation_number: ''
    }
});

const event_loaded = ref({
    event_address: {
        street: '',
        house_number: '',
        city: '',
        postal_code: '',
        country_code: 'CZ',
        orientation_number: ''
    }
});

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'event_address': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    attachements: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    type: { value: null, matchMode: FilterMatchMode.STARTS_WITH }, // Add this line
});

const statuses = ref(['zrušená', 'proběhlá', 'nová']);
const loading = ref(true);
const eventDialog = ref(false);
let eventUpdateSuccessful = ref(false);

let responseData = ref({});

const addressErrorMap = {
    'event_address.country': "Země původu",
    'event_address.city': "Město",
    'event_address.street': "Ulice",
    'event_address.house_number': "Číslo popisné",
    'event_address.orientation_number': "Číslo orientační",
    'event_address.postal_code': "Poštovní směrovací číslo",
    // Add more mappings as needed
};

axiosClient.interceptors.response.use(
    response => response,
    error => {
        console.error('Axios eventUpdateSuccessful', error);
        return Promise.reject(error);
    }
);


function getDateFromIso(isoDate) {
    console.log("Parsing date:");
    console.log(isoDate);
    // Convert the string to a Date object
    const date = new Date(isoDate);

    // Format the date as YYYY-MM-DD
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // getUTCMonth() returns 0-11
    const day = date.getUTCDate().toString().padStart(2, '0');

    // Combine the formatted parts
    let date_edited = `${year}-${month}-${day}`;
    return date_edited;
}


// Messages init
let errors = ref({});
let programError = ref(false);
let success = ref('');


onMounted(() => {
    fetchEvents();
});

const fetchEvents = async () => {
    try {
        await axiosClient.get('/sanctum/csrf-cookie');
        const response = await axiosClient.get('/events'); // Adjust the API endpoint as needed
        events.value = response.data.map((item) => {
            // First, handle the date_from
            const mappedItem = { ...item, date_from: getDateFromIso(item.date_from),
                date_to: getDateFromIso(item.date_to) };

            // Now, handle the event_address
            if (mappedItem.event_address == null) {
                mappedItem.event_address = {
                    street: '',
                    house_number: '',
                    city: '',
                    postal_code: '',
                    country: '',
                    // Add any other address fields you have
                };
            }
            return mappedItem;
        });

        console.log("events after fetch and mapping:");
        console.log(events.value);

        loading.value = false;
    } catch (error) {
        console.log("Error catched when fetching events" + error);
        if (error.response && error.response.status === 422 && error.response.data.errors) {
            console.log(error.response.data.errors);
            errors.value = error.response.data.errors;
        } else if (error.message) {
            console.log("error message:");
            console.log(error.message);
            errors.value = { general: [error.message] };
        } else {
            console.log("error:");
            console.log(error);
            errors.value = { general: ['An unexpected error occurred.'] };
        }
    }
};


const hasAnyError = computed(() => {
    console.log("errors.value:c" + errors.value);
    console.log(Object.keys(errors.value).length);
    return Object.keys(errors.value).length > 0;
});

const formatDate = (value) => {
    if (!value) {
        // Handle the case where the value is null, undefined or an empty string
        return '';
    }
    const date = new Date(value);
    if (isNaN(date)) {
        // Handle the case where the value is not a valid date string
        return 'Invalid Date';
    }
    return date.toLocaleDateString('cs', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};

const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const getStatus = (status) => {
    switch (status) {
        case 'zrušená':
            return 'danger';

        case 'proběhlá':
            return 'success';

        case 'nová':
            return 'info';
    }
}

// Fetch to dialog window
const editEvent = (eventData) => {
    console.log('editevent called with:', eventData);
    event_loaded.value = {
        ...eventData,
        event_address: eventData.event_address || {
            street: '',
            house_number: '',
            city: '',
            postal_code: '',
            country: '',
            orientation_number: ''
        }
    };
    eventDialog.value = true;
};

const hideDialog = () => {
    eventDialog.value = false;
};


// Trash button delete
const deleteEvent = async (eventId, eventName) => {
    if (confirm(`Jste si jistí, že chcete smazat akci ${eventName}?`)) {
        try {
            const response = await EventService.deleteEvent(eventId);
            if (response.status === 200) {
                toast.add({ severity: 'success', summary: 'Success', detail: 'akce úspěšně smazán.', life: 3000 });
                fetchEvents();
            }
        } catch (error) {
            console.error('Error deleting event:', error);
            console.log(error);
            toast.add({ severity: 'error', summary: 'Error', detail: 'Chyba při smazání uživatele.', life: 3000 });
        }
    }
};

const createEvent = async () => {
    try {
        console.log('Event to be sent:', JSON.stringify(event.value, null, 2)); // Log the event object
        const response = await axiosClient.post('/events', event);
        console.log('Event saved:', response.data);
        fetchEvents();
        errors.value = {}; // Clear any previous errors
    } catch (error) {
        console.error('Error saving event:', error);
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);

        if (error.response && error.response.status === 422 && error.response.data.errors) {
            errors.value = error.response.data.errors;
        } else if (error.message) {
            errors.value = { general: [error.message] };
        } else {
            errors.value = { general: ['An unexpected error occurred.'] };
        }
    }
};


const saveEvent = async () => {
    // Assuming that 'event' is a reactive object like 'event'

    try {
        const updateResponse = await updateEvent();
        console.log("updateResponse:" + updateResponse);
        console.log(updateResponse);
        console.log(updateResponse.value);
        console.log(updateResponse.message);
        const responseText = updateResponse.value[0];

        if (eventUpdateSuccessful.value === true) {
            toast.add({ severity: 'success', summary: 'Uložení úspěšné', detail: responseText, life: 3000 });

            // Close the dialog and reset the form
            eventDialog.value = false;
            // TODO: In EmployeesManagement.vue there was also reset form, not sure if needed here. Check if needed. PRIORITY: A
            fetchEvents(); // non-lazy fetch so far (loading all at once)
        } else {
            // Check if there are any errors
            if (hasAnyError.value) {
                // Don't submit the form if there are errors
                console.log("hasAnyError.value" + hasAnyError.value);
                toast.add({ severity: 'error', summary: 'Chyba při aktualizaci akce', detail: responseText, life: 3000 });
                return;
            } else {
                console.log("No hasAnyError.value text found but did not found sucessful response either.");
                toast.add({ severity: 'error', summary: 'Chyba při aktualizaci akce', detail: responseText, life: 3000 });
            }
        }
    } catch (error) {
        console.error(error);
        toast.add({ severity: 'error', summary: 'Chyba při aktualizaci akce', detail: 'Nepodařilo se uložit akce', life: 3000 });
    }
};


async function updateEvent() {
    // Resources: https://laraveldaily.com/post/laravel-vue-how-to-display-validation-errors

    await axiosClient.get('/sanctum/csrf-cookie').catch(error => {
        console.log(error);
        programError.value = true;
    });

    const eventData = toRaw(event_loaded.value);

    await axiosClient.put('/admin/events/update', eventData)
        .then(response => {
            console.log("Register response:");
            console.log(response);
            console.log(response.data);

            success.value = response.data.message;
            eventUpdateSuccessful.value = true;
            console.log("eventData.id:");
            console.log(eventData.id);

            responseData.value = response.data;
            console.log("responseData:");
            console.log(responseData);
        })
        .catch(error => {
            console.log("error catched when updating event");
            console.log(error);
            console.log(error.response);
            eventUpdateSuccessful.value = false;
            if(error.response) {
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                } else {
                    programError.value = true;
                    console.log("Other error");
                    console.log(error);
                }
            } else {
                console.log("error without .response " + error);
                programError.value = true;
            }
        });


    if(eventUpdateSuccessful.value === true) {

        console.log("Updating event.");

        // let event_id = responseData['event']['id'];

        console.log("eventData:");
        console.log(eventData);

        let event_id = event_loaded.value.id;
        console.log("User ID: " + event_id);

        await axiosClient.get('/sanctum/csrf-cookie')
            .catch(error => {
                console.log(error);
                programError.value = true;
                eventUpdateSuccessful.value = false;
            });

        console.log("errors after update: " + errors);
    }

    return responseData;
}

const pageTitle = 'Správa akcí';


</script>

<template>
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">{{ pageTitle }}</h2>
    </div>

    <main>
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <!-- Edit event style -->
            <Toast />
            <!-- TODO: Move this to a separate component -->
            <Dialog v-model:visible="eventDialog" :style="{width: '70%'}" :modal="true" class="p-fluid">
                <form class="space-y-6" action="#" @submit.prevent="saveEvent">
                    <div class="card">
                        <div class="mb-2 sm:mb-6">
                            <h1 id="event-edit-headline" class="text-3xl font-bold tracking-tight text-gray-900">Úpravy akce</h1>
                        </div>

                        <div>
                            <h2>Úprava akce</h2>
                            <BaseInput
                                label="Název akce*"
                                name="name"
                                id="event_edit_name"
                                v-model="event_loaded.name"
                                placeholder="Název akce"
                                required
                            />
                            <span class="text-red-600" v-if="errors?.name">{{ errors.name[0] }}</span>
                        </div>

                        <h2 class="text-lg font-semibold text-gray-900">Adresa akce</h2>
                        <AddressComponent
                            v-if="event_loaded.event_address"
                            :object_address="event_loaded.event_address"
                            suffix="edited"
                            :isRequired="true"
                        ></AddressComponent>

                        <h2 class="text-lg font-semibold text-gray-900">Termín</h2>

                        <div>
                            <BaseInput
                                id="event_edit_date_from"
                                label="Datum od*"
                                name="date_from"
                                type="date"
                                v-model="event_loaded.date_from"
                                required
                            />
                            <span class="text-red-600" v-if="errors?.date_from">{{ errors.date_from[0] }}</span>
                        </div>

                        <div>
                            <BaseInput
                                id="event_edit_date_to"
                                label="Datum do*"
                                name="date_to"
                                type="date"
                                v-model="event_loaded.date_to"
                                required
                            />
                            <span class="text-red-600" v-if="errors?.date_to">{{ errors.date_to[0] }}</span>
                        </div>

                        <div>
                            <BaseInput
                                id="event_edit_time_from"
                                label="Čas od*"
                                name="time_from"
                                type="time"
                                v-model="event_loaded.time_from"
                                required
                            />
                            <span class="text-red-600" v-if="errors?.time_from">{{ errors.time_from[0] }}</span>
                        </div>

                        <div>
                            <BaseInput
                                id="event_edit_time_to"
                                label="Čas do*"
                                name="time_to"
                                type="time"
                                v-model="event_loaded.time_to"
                                required
                            />
                            <span class="text-red-600" v-if="errors?.time_to">{{ errors.time_to[0] }}</span>
                        </div>

                        <div>
                            <BaseInput
                                id="event_edit_salary"
                                label="Odměna (základ)*"
                                name="salary"
                                type="number"
                                v-model="event_loaded.salary"
                                required
                            />
                            <span class="text-red-600" v-if="errors?.salary">{{ errors.salary[0] }}</span>
                        </div>

                        <div>
                            <label for="status" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
                            <Dropdown v-model="event_loaded.status" :options="statuses" placeholder="Vyberte status" class="w-full" />
                            <span class="text-red-600" v-if="errors?.status">{{ errors.status[0] }}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <Button label="Zrušit" icon="pi pi-times" text @click="hideDialog"/>
                            <BaseBtn
                                type="submit"
                                text="Uložit akci"
                            />
                        </div>
                        <div id="program-error" v-if="programError" class="error-message" style="color: red">
                            Systém je dočasně nedostupný, vyzkoušejte prosím později.
                        </div>
                        <div id="has-any-error" v-if="hasAnyError" class="text-red-600">
                            Prosím, opravte chyby ve formuláři.
                        </div>
                        <div id="success" v-if="success" class="text-green-600">
                            Odeslání formuláře bylo úspěšné.
                        </div>
                    </div>

                </form>
            </Dialog>
        </div>
    </main>

    <!-- New event section -->
    <div class="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
        <div class="mt-10 sm:w-full sm:max-w-sm">
            <form class="space-y-6" action="#" @submit.prevent="createEvent">
                <BaseInput
                    label="Název akce"
                    name="name"
                    v-model="event.name"
                    placeholder="Název akce"
                    required
                />

                <h2 class="text-lg font-semibold text-gray-900">Adresa akce</h2>
                <AddressComponent :object_address="event.event_address" suffix="primary" :isRequired="true"></AddressComponent>

                <h2 class="text-lg font-semibold text-gray-900">Termín</h2>

                <BaseInput
                    label="Datum od"
                    name="date_from"
                    type="date"
                    v-model="event.date_from"
                    required
                />

                <BaseInput
                    label="Datum do"
                    name="date_to"
                    type="date"
                    v-model="event.date_to"
                    required
                />

                <BaseInput
                    label="Čas od"
                    name="time_from"
                    type="time"
                    v-model="event.time_from"
                    required
                />

                <BaseInput
                    label="Čas do"
                    name="time_to"
                    type="time"
                    v-model="event.time_to"
                    required
                />

                <BaseInput
                    label="Odměna (základ)"
                    name="salary"
                    type="number"
                    v-model="event.salary"
                    required
                />

                <div>
                    <label for="status" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
                    <Dropdown v-model="event.status" :options="statuses" placeholder="Vyberte status" class="w-full" />
                </div>

                <div v-if="Object.keys(errors).length > 0" class="text-red-600">
                    <p>Chyba při ukládání události:</p>
                    <ul>
                        <li v-for="(errorList, field) in errors" :key="field">
                            <strong>{{ field }}:</strong> {{ Array.isArray(errorList) ? errorList.join(', ') : errorList }}
                        </li>
                    </ul>
                </div>
                <div>
                    <BaseBtn
                        type="submit"
                        text="Uložit novou akci"
                    />
                </div>

            </form>
        </div>
    </div>
    <div class="p-2 md:p-4">
        <!-- Data table section -->
        <div class="mt-8 px-4 sm:px-6 lg:px-8">
            <h2 class="text-lg font-semibold text-gray-900">Akce</h2>
            <div class="mt-4">
                <DataTable v-model:selection="selectedEventId" v-model:filters="filters" :value="events" paginator :rows="10" dataKey="id" filterDisplay="menu"
                           :loading="loading"
                           :globalFilterFields="['name', 'type', 'event_address', 'date', 'time']">
                    <template #header>
                        <div class="flex justify-content-end">
                            <IconField iconPosition="left">
                                <InputText v-model="filters['global'].value" placeholder="Hledat dle klíčového slova" />
                            </IconField>
                        </div>
                    </template>
                    <template #empty> Nebyly nalezeny žádné směny. </template>
                    <template #loading> Načítám data o akcích. Prosíme o strpení. </template>

                    <Column v-if="props.selectionEnabled" selectionMode="single" headerStyle="width: 3em"></Column>
                    <Column field="name" header="Název akce" style="min-width: 12rem" sortable>
                        <template #body="{ data }">
                            {{ data.name }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Hledat dle jména" />
                        </template>
                    </Column>

                    <Column field="event_address" header="Adresa" filterField="event_address" style="min-width: 12rem">
                        <template #body="{ data }">
                            <div v-if="data.event_address" class="flex align-items-center gap-2">
                                <span>{{ data.event_address.street }}, {{ data.event_address.house_number }}, {{ data.event_address.city }}, {{ data.event_address.postal_code }}</span>
                            </div>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country" />
                        </template>
                    </Column>

                    <Column field="status" header="Status" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
                        <template #body="{ data }">
                            <Tag :value="data.status" :severity="getStatus(data.status)" />
                        </template>
                    </Column>

                    <Column field="date_from" header="Datum od" style="min-width: 12rem" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.date_from) }}
                        </template>
                    </Column>

                    <Column field="time_from" header="Čas od" style="min-width: 12rem" sortable>
                        <template #body="{ data }">
                            {{data.time_from }}
                        </template>
                    </Column>

                    <Column field="date_to" header="Datum do" style="min-width: 12rem" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.date_to) }}
                        </template>
                    </Column>

                    <Column field="time_to" header="Čas do" style="min-width: 12rem" sortable>
                        <template #body="{ data }">
                            {{data.time_to }}
                        </template>
                    </Column>

                    <Column field="verified" header="Ověření" dataType="boolean" style="min-width: 6rem">
                        <template #body="{ data }">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500': data.verified, 'pi-times-circle text-red-400': !data.verified }"></i>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="edits" header="Úpravy" style="min-width:8rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" outlined rounded class="mr-2"
                                    @click="editEvent(slotProps.data)"  />
                            <Button icon="pi pi-trash" outlined rounded severity="danger"
                                    @click="deleteEvent(slotProps.data.id, slotProps.data.name)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- END Data table section -->
</template>

