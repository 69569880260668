<script setup>

import { ref, onMounted, computed, toRaw } from 'vue';
import { DocumentService as DocumentService } from '@/services/DocumentService';
import { AttributesService } from '@/services/AttributesService';
import { FilterMatchMode } from 'primevue/api';

import axiosClient from "@/axios";
import Toast from "primevue/toast";
import FileUpload from "primevue/fileupload";
import Button from 'primevue/button';
import BaseBtn from "@/components/BaseBtn.vue";
import IconField from 'primevue/iconfield';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import DataTable from "primevue/datatable";
import { useToast } from 'primevue/usetoast';
import { reactive } from "vue";

const userRole = ref({});

const nodes = ref(null);
const documents = ref();
const document_file = ref({});
let loading = ref(false);
const documentDialog = ref(false);
const authError = '';
let programError = ref(false);
const toast = useToast();
let errors = ref({});
let uploadedFile = reactive(null);
let displayEmployeeDocuments = ref(false);
let fileWasUploaded = ref(false);
let fileWasUploadedError = ref(false);
let responseData = ref({});


const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    file_path_employee: { value: null, matchMode: FilterMatchMode.CONTAINS },
    file_path_employer: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
let fileWasSelected = ref(false);

// computed properties
const hasAnyError = computed(() => {
    console.log("hasAnyError:");
    console.log(errors.value);
    // debug
    if(errors.value) {
        if(errors.value['personal_number_part_1']) {
            console.log("errors.value['personal_number_part_1']:");
            console.log(errors.value['personal_number_part_1'][0]);
        }

        // this works for the nested attribute objects
        // personal_number.part_1
        if(errors.value['personal_number.part_1']) {
            console.log("errors.value.personal_number.part_1[0]:");
            console.log(errors.value['personal_number.part_1'][0]);
        }

        if(errors.value['personal_number_merged']) {
            console.log("errors.value.personal_number_merged[0]:");
            console.log(errors.value['personal_number_merged'][0]);
        }
    }

    console.log("App received following errors:");
    for (const [key, value] of Object.entries(errors.value)) {
        console.log("Received error:");
        console.log(`${key}: ${value}`);
        errorMessages.push(`${value}`);
    }

    return Object.keys(errors.value).length > 0;
});

const onSelectedFiles = (event) => {
    console.log("event:");
    console.log(event);
    console.log("file selected");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    fileWasSelected.value = true;
};

onMounted(async () => {

    await axiosClient.get('/sanctum/csrf-cookie');
    let {data} = await axiosClient.get('/api/user');
    console.log("data:");
    console.log(data);
    console.log("data.id.value:", data.id);

    const user_id = data.id;
    console.log("user_id:", user_id);

    DocumentService.getDocumentsAll(user_id).then((data) => {
        documents.value = getDocuments(data);
        console.log("documents.value:");
        console.log(documents.value);
        loading.value = false;
    });

    AttributesService.getTreeNodes().then(data => nodes.value = data);

    console.log("documents:");
    console.log(documents);

    await axiosClient.get('/get-permissions')
        .then((response) => {
            console.log("response:");
            console.log(response);
            if(response.data.length === 0) {
                userRole.value = "user";
            } else {
                userRole.value = response.data;
            }
        }).catch((error) => {
            console.log("Error in get-permissions");
            console.log(error);
        }
    );
    console.log("userRole:");
    console.log(userRole.value);

    if(userRole) {
        console.log("userRole inside watch:");
        console.log(userRole.value.data);
        if (userRole.value.includes('see_admin_sections') || userRole.value.includes('see_superadmin_sections')) {
            displayEmployeeDocuments.value = false;
        } else {
            displayEmployeeDocuments.value = true;
        }
    }
});



const formatDate = (value) => {
    if (!value) {
        // Handle the case where the value is null, undefined or an empty string
        return '';
    }
    const date_at = new Date(value);
    if (isNaN(date_at)) {
        // Handle the case where the value is not a valid created_at string
        return 'Invalid Date';
    }
    return date_at.toLocaleDateString('cs', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};



const hideDialog = async () => {
    documentDialog.value = false;

    await axiosClient.get('/sanctum/csrf-cookie');
    let {data} = await axiosClient.get('/api/user');
    console.log("data:");
    console.log(data);
    console.log("data.id.value:", data.id);

    const user_id = data.id;
    console.log("user_id:", user_id);
    // Refresh
    DocumentService.getDocumentsAll(user_id).then((data) => {
        documents.value = getDocuments(data);
        console.log("documents.value:");
        console.log(documents.value);
        loading.value = false;
    });
}


const getDocuments = (data) => {
    return [...(data || [])].map((d) => {
            if (d.created_at) {
            d.created_at = new Date(d.created_at);
            d.updated_at = new Date(d.updated_at);
            if (isNaN(d.created_at.getTime())) {
                console.warn(`Invalid created_at for document_file ${d.id}: ${d.created_at}`);
                d.created_at = null; // or set to a default created_at
            }
            if (isNaN(d.updated_at.getTime())) {
                console.warn(`Invalid updated_at for document_file ${d.id}: ${d.updated_at}`);
                d.updated_at = null; // or set to a default updated_at
            }
        }
        return d;
    });
};


const saveDocument = async () => {

    const updateResponse = await updateDocument();
    console.log("updateResponse");
    console.log(updateResponse);
    console.log(updateResponse.value[1]);
    const responseText = updateResponse.value[0];

    if (updateResponse.value[1] === 204 || updateResponse.value[1] === 200) {
        toast.add({ severity: 'success', summary: 'Uložení úspěšné', detail: responseText,
        life: 3000 });

            // Close the dialog and reset the form
        documentDialog.value = false;

        DocumentService.getDocumentsAll().then((data) => {
        users.value = getDocuments(data);
        loading.value = false;
    });

    } else {
        toast.add({ severity: 'error', summary: 'Chyba při aktualizaci zaměstnance', detail: responseText,
        life: 3000 });

        // Close the dialog and reset the form
        documentDialog.value = false;
    }
};

const editDocument = (documentData) => {
    console.log('editDocument called with:', documentData);
    console.log(documentData);
    document_file.value = { ...documentData };
    documentDialog.value = true;
    console.log("document_file.value:");
    console.log(document_file.value);
};

async function updateDocument() {
    // Resources: https://laraveldaily.com/post/laravel-vue-how-to-display-validation-errors

    if(fileWasSelected.value === true && fileWasUploaded.value === false) {
        fileWasUploadedError.value = true;
        return;
    }

    await axiosClient.get('/sanctum/csrf-cookie').catch(error => {
        console.log(error);
        programError.value = true;
    });

    const documentData = toRaw(document_file.value);

    console.log("documentData:");
    console.log(documentData);

    await axiosClient.put('/user/documents/create/file-upload', documentData)
        .then(response => {
            console.log("Register response:");
            console.log(response);
            console.log(response.data);

            success.value = response.data.message;
            userUpdateSuccesful.value = true;
            console.log("documentData.id:");
            console.log(documentData.id);

            responseData.value = response.data;
            console.log("responseData:");
            console.log(responseData);
        })
        .catch(error => {
            if(error.response) {
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                } else {
                    programError.value = true;
                    console.log("Other error:");
                    console.log(error);
                }
            } else {
                console.log("Error: " + error);
                programError.value = true;
            }
            responseData.value = error;
        });

    return responseData;
}

const customUpload = async (event, url, idDocument) => {
  console.log("event:", event);
  console.log("custom file uploaded");

  uploadedFile = event.files[0];

  console.log("uploadedFile:", uploadedFile);

  // Create a new div element for the custom message
  const customMessage = document.createElement('div');
  customMessage.innerHTML = '<p style="color: green;">Soubor úspěšně nahrán.</p>';
  customMessage.classList.add('custom-message-class');

  // Locate the button bar where the buttons are located
  const buttonBar = document.querySelector('.p-fileupload-buttonbar');

  console.log("buttonBar:", buttonBar);

  // The upload itself...
  let formFileData = new FormData();

  await axiosClient.get('/sanctum/csrf-cookie');
  let {data} = await axiosClient.get('/api/user');
  console.log("data:");
  console.log(data);
  console.log("data.id.value:", data.id);

  const user_id = data.id;
  console.log("user_id:", user_id);

  console.log("document_file:");
  console.log(document_file);
  console.log(document_file.value);
  console.log("document_file.value.document_type:");
  console.log(document_file.value.document_type);

  formFileData.append('user_id', user_id);
  formFileData.append('file', uploadedFile);
  formFileData.append('id_document', idDocument);
  formFileData.append('document_type', document_file.value.document_type);

  try {
    await axiosClient.get('/sanctum/csrf-cookie');

    const response = await axiosClient.post(url, formFileData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });

    console.log("response:", response);

    // Now insert the custom message into the DOM, before the contentArea
    buttonBar.append(customMessage);

    fileWasUploaded.value = true;
    fileWasUploadedError.value = false;

    // Handle success (e.g., update user image path)
    if (url === '/user/profile/img-upload') {
      user.img_path = response.data.img_path;
    }

    toast.add({ severity: 'success', summary: 'Úspěch', detail: 'Soubor byl úspěšně nahrán', life: 3000 });
  } catch (error) {
    console.error("Error uploading file:", error);
    fileWasUploadedError.value = true;
    toast.add({ severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se nahrát soubor', life: 3000 });
  }
};

</script>

<template>
    <header class="bg-white shadow">
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold tracking-tight text-gray-900">Soubory zaměstnance</h1>
        </div>
    </header>

    <div class="p-2 md:p-4">
        <Dialog v-model:visible="documentDialog" :style="{width: '70%'}" :modal="true" class="p-fluid">
            <form class="space-y-6" action="#" @submit.prevent="saveDocument">
                <div class="mb-2 sm:mb-6">
                    <h2 class="text-2xl font-bold sm:text-xl">Soubory zaměstnance</h2>
                    <div class="mb-2">
                        <a :href="document_file.employer_document_url" target="_blank">Dokument zaměstnavatele: {{ document_file.file_path_employer }}</a>
                    </div>
                    <div class="mb-2" v-if="document_file.employee_document_url">
                        <a :href="document_file.employee_document_url" target="_blank">Váš nahraný dokument: {{ document_file.file_path_employee }}</a>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="card">
                        <Toast />
                        <FileUpload
                            @upload="onAdvancedUpload($event)"
                            :multiple="false"
                            name="demo[]"
                            aria-labelledby="fileUploadLabel"
                            accept=".pdf"
                            :maxFileSize="10000000"
                            fileLimit="1"
                            cancelLabel="Zrušit"
                            chooseLabel="Vybrat"
                            uploadLabel="Nahrát"
                            invalidFileSizeMessage="Velikost souboru je měl být než 10 MB."
                            @uploader="(event) => customUpload(event,
                            'user/documents/update/file-upload', document_file.id_document)"
                            @select="onSelectedFiles($event)"
                            customUpload
                        >
                            <template #empty>
                                <p>Přetáhni sem soubor pro nahrání. Povoleno je PDF.</p>
                            </template>
                        </FileUpload>
                        <p id="file-upload-instruction">Po vybrání souboru klikněte na <b>Nahrát</b>.</p>
                    </div>
                </div>
                <ul v-if="hasFileUploadError" class="text-red-600">
                    {{ hasFileUploadError }}
                </ul>

                <div v-if="authError" class="error-message" style="color: red">
                    {{ authError }}
                </div>

                <div>
                    <p>Pokud se vám výše objevila zpráva o úspěšném nahrání souboru, můžete okno pro nahrání soiboru zavřít.</p>
                    <div class="mt-4">
                        <Button label="Zavřít okno pro nahrání souboru" icon="pi pi-times" text @click="hideDialog"/>
                    </div>
                    <div id="program-error" v-if="programError" class="error-message" style="color: red">
                        Systém je dočasně nedostupný, vyzkoušejte prosím později.
                    </div>
                    <div data-cy="has-any-error" v-if="hasAnyError" class="text-red-600">
                        Prosím, opravte chyby ve formuláři.
                    </div>
                    <div id="file-upload-error" v-if="fileWasUploadedError" class="error-message" style="color: red">
                        Nahrejte prosím fotografii stiskem na tlačítko "Nahrát".
                    </div>
                    <div id="success" v-if="success" class="text-green-600">
                        Odeslání formuláře bylo úspěšné.
                    </div>
                </div>

            </form>
        </Dialog>
    </div>

    <div id="employee-documents" class="card" v-if="displayEmployeeDocuments">

        <h1 class="pl-6 text-2xl font-bold sm:text-xl">Dokumenty zaměstnance</h1>

        <DataTable v-model:filters="filters" :value="documents" paginator :rows="10" dataKey="id" filterDisplay="row"
        :loading="loading"
        :globalFilterFields="['file_path_employer', 'file_path_employee', 'created_at', 'status', 'attachements.image']">
            <template #header>
                <div class="flex justify-content-end">
                    <IconField iconPosition="left">
                        <InputText v-model="filters['global'].value" placeholder="Hledat klíčová slova..." />
                    </IconField>
                </div>
            </template>
            <template #empty> Načítání informací o zaměstnancích... </template>
            <template #loading> Načítám informace o zaměstnancích, prosím čekejte. </template>

            <Column field="file_path_employee" header="Dokument zaměstnance" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    <div v-if="data.file_path_employee">
                        <a v-if="data.file_name_employee" :href="`${data.url_employee}`" target="_blank">
                            {{ data.document_type }}: {{ data.file_name_employee || 'Dokument bez názvu' }}
                        </a>
                        <a v-else :href="`${data.url_employee}`" target="_blank">Dokument bez názvu</a>
                    </div>
                    <div v-else>Přidejte požadovaný soubor.</div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Vyhledat dle jména" />
                </template>
            </Column>

            <Column field="file_path_employer" header="Dokument zaměstnavatele" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    <div v-if="data.file_path_employer">
                        <a v-if="data.file_name_employer_original" :href="`${data.url_employer}`" target="_blank">
                            {{ data.document_type }}: {{ data.file_name_employer_original || 'Dokument bez názvu' }}
                        </a>
                        <a v-else :href="`${data.url_employer}`" target="_blank">Dokument bez názvu</a>
                    </div>
                    <div v-else>Soubor nemá URL uloženého dokumentu.</div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Vyhledat dle jména" />
                </template>
            </Column>

            <Column field="created_at" header="Datum nahrání" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    {{ formatDate(data.created_at) }} <!-- Assuming formatDate is a method that formats Date objects -->
                </template>
            </Column>

            <Column field="updated_at" header="Datum aktualizace" style="min-width: 12rem" sortable>
                <template #body="{ data }">
                    {{ formatDate(data.updated_at) }} <!-- Assuming formatDate is a method that formats Date objects -->
                </template>
            </Column>

            <Column field="edits" header="Úpravy" style="min-width:8rem">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="editDocument(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

</template>
