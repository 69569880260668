export function useHasAddressErrpr() {
    // Your function logic here
    const hasAddressError = () => {
      // Function implementation
      console.log("hasAnyError:");
      console.log(errors.value);
      
      if(errors.value) {
          for (const [key, humanReadableKey] of Object.entries(errorMap)) {
              if (errors.value[key]) {
                  errors.value[key].forEach((error) => {
                      const formattedKey = formatErrorKey(key);
                      // Use a case-insensitive regular expression for replacement
                      const messageWithoutFieldName = error.replace(new RegExp(formattedKey, 'i'), humanReadableKey);
                      errorMessages.push(messageWithoutFieldName);
                  });
              }
          }
  
          return errorMessages;
      }
  
      return false;
    }
  
    return {
      hasAddressError
    }
}
