<script>
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import Dialog from "primevue/dialog";
import EventsManagement from "./EventsManagement.vue";
import Checkbox from 'primevue/checkbox';


export default {
  name: 'invitationsManagement',
  components: {
    Checkbox, // Add this line to register the Checkbox component
    DataTable,
    Button,
    Dialog,
  },
  data() {
    return {
      pageTitle: 'Správa směn',
      event: {
        name: 'Obecní ples 2024',
        date: '',
        time: '',
        address: {country: '', countryCode: '', city: '', street: '', postal_code: '', house_number: ''}
      },
      error: null,
      checked: false
    };
  }
}

</script>

<template>
<header class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900">Správa zaměstnanců</h1>
    </div>
</header>
<main>
  <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">

  <!-- Edit user style -->
    <div class="p-2 md:p-4">
      <div class="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
        <h1 class="text-2xl font-bold sm:text-xl">Pozvánky na směny</h1>
          <div class="flex flex-col items-center w-full mt-4 mb-4 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <Dropdown v-model="selectedEvent" :options="events" filter optionLabel="name" placeholder="Vyberte akci" class="w-full md:w-14rem">
                <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                    </div>
                </template>
            </Dropdown>
            </div>
            <div
                class="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                <div class="w-full">
                  <p class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                    <b>Název akce: </b>{{ selectedEvent ? selectedEvent.name : '' }}
                  </p>
                </div>

            </div>

            <!-- FROM REGISTER -->
            <h2>Adresa akce</h2>
            <div class="mb-2 sm:mb-6">
              <label for="country" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Národnost*</label>
              <div class="mt-2">
                <v-select v-model="event.address.countryCode" :options="selectCountryOptions"
                          :reduce="country => country.code" label="country" />
                <input type="hidden" name="country" v-model="event.address.country" required>
              </div>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="address" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Kód země*</label>
              <div class="mt-2">
                <input id="country_code" name="country_code" type="text" autocomplete="country_code"
                       placeholder="Kód země"
                       v-model="event.address.countryCode"
                       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6" required disabled/>
              </div>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="city" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Město*</label>
              <input id="city" name="city" type="text" autocomplete="city"
                     placeholder="Město"
                     v-model="event.address.city"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6" required/>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="postal_code" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                PSČ*
              </label>
              <input id="postal_code" name="postal_code" type="text" autocomplete="postal_code"
                     placeholder="PSČ"
                     v-model="event.address.postal_code"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     pattern="[0-9]{3} [0-9]{2}"
                     required/>
              <small>Zadejte s mezerou</small>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="street" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Ulice*</label>
              <input id="street" name="street" type="text" autocomplete="street"
                     placeholder="Ulice"
                     v-model="event.address.street"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6" required/>
            </div>

            <div class="mb-2 sm:mb-6">
              <label for="house_number" class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">
                Číslo popisné*
              </label>
              <input id="house_number" name="house_number" type="text" autocomplete="house_number"
                     placeholder="Číslo popisné"
                     v-model="event.address.house_number"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                           ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                           focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     pattern="[0-9]+"
                     required/>
            </div>
      </div>
    </div>

    <div class="p-2 md:p-4">

      <div class="flex justify-start">
        <button type="submit"
              class="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4
              focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full
              sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700
              dark:focus:ring-indigo-800">
          Pozvat hromadně
        </button>
      </div>
      <div class="flex justify-start">
        <p class="block mb-2 text-sm font-medium text-indigo-900 dark:text-white">Zaškrtněte níže pro hromadné odeslání pozvánky</p>
      </div>
      <h2 class="pl-6 text-2xl font-bold sm:text-xl">Zaměstnanci</h2>

      <!-- Data table section -->
      <!-- Use, e.g. @click="editProduct(slotProps.data)" -->

      <!-- NOTE: There can be also country code and other stuff, check the official docummentation. -->
      <div class="card">
          <DataTable v-model:filters="filters" :value="filteredEmployees" paginator :rows="10" dataKey="id" filterDisplay="row" :loading="loading"
                  :globalFilterFields="['select', 'name', 'address', 'date', 'position', 'status', 'attachements.image']" 
                  selectionMode="multiple"
                  v-model:selection="selectedEmployee">
              <template #header>
                  <div class="flex justify-content-end">
                      <IconField iconPosition="left">
                          <InputText v-model="filters['global'].value" placeholder="Vyhledat" />
                      </IconField>
                  </div>
              </template>
              <template #empty> Nebyl nalezen žádný zaměstnanec. </template>
              <template #loading> Načítám informace o zaměstnancích, prosím čekejte. </template>

              <Column field="select" header="Výběr" style="min-width: 4rem" selectionMode="multiple"></Column>

              <Column field="name" header="Jméno" style="min-width: 12rem" sortable>
                  <template #body="{ data }">
                      {{ data.name }}
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Vyhledat dle jména" />
                  </template>
              </Column>
              <Column header="Adresa" filterField="address" style="min-width: 12rem">
                  <template #body="{ data }">
                      <div class="flex align-items-center gap-2">
                          <span>{{ data.address.street }}, {{ data.address.house_number }}, {{ data.address.city }}, {{ data.address.postal_code }}</span>
                      </div>
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Vyhledat dle adresy" />
                  </template>
              </Column>

              <Column header="Fotografie" filterField="attachements" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
                <template #body="{ data }">
                    <div class="flex align-items-center gap-2">
                        <a :href="`https://primefaces.org/cdn/primevue/images/avatar/${data.attachements.image}`" target="_blank" class="text-blue-500 hover:text-blue-700">
                          <img :alt="data.attachements.image" :src="`https://primefaces.org/cdn/primevue/images/avatar/${data.attachements.image}`" style="width: 32px" />
                        </a>
                    </div>
                </template>
              </Column>

              <Column field="status" header="Status" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
                  <template #body="{ data }">
                      <Tag :value="data.status" :severity="getSeverity(data.status)" />
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Vyberte" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                          <template #option="slotProps">
                              <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                          </template>
                      </Dropdown>
                  </template>
              </Column>

              <Column field="position" header="Pozice" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
                  <template #body="{ data }">
                      <Tag :value="data.position" :severity="getSeverity(data.position)" />
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                      <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="positions" placeholder="Vyberte" class="p-column-filter" style="min-width: 12rem" :showClear="true">
                          <template #option="slotProps">
                              <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                          </template>
                      </Dropdown>
                  </template>
              </Column>

              <Column field="edits" filterField="attachements" header="Úpravy" style="min-width:8rem">
                  <template #body="{ data }">
                      <Button label="Pozvat"/>
                      <Button label="Kontakt" @click="openDialog(data, true)" />
                      <Dialog v-model:visible="visible" modal header="Kontakt" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        <div class="inline-flex align-items-center justify-content-center gap-2">
                          <div class="flex align-items-center gap-2">
                              <a :href="`https://primefaces.org/cdn/primevue/images/avatar/${selectedData.attachements.image}`" target="_blank" class="text-blue-500 hover:text-blue-700">
                                <img :alt="selectedData.attachements.image" :src="`https://primefaces.org/cdn/primevue/images/avatar/${selectedData.attachements.image}`" style="width: 32px" />
                              </a>
                          </div>
                          <!--Example of how to load the data from the table -->
                          <span class="font-bold white-space-nowrap">{{ selectedData.name }}</span>
                        </div>
                        <div class="flex align-items-center gap-3 mb-3">
                          <p class="m-0">
                            <b>Email:</b> test@me.com
                          </p>
                        </div>
                        <div class="flex align-items-center gap-3 mb-3">
                          <p class="m-0">
                            <b>Telefon:</b> +420 782 123 456 
                          </p>
                        </div>
                        <template #footer>
                          <Button label="Cancel" text severity="secondary" @click="openDialog(data, false)" autofocus />
                        </template>
                      </Dialog>
                  </template>
              </Column>
          </DataTable>
      </div>
    </div>
    <!-- END Data table section -->
  </div>

</main>

</template>

<script setup>

//import {Datatable, initTE} from "tw-elements";
import {onMounted, ref, computed} from "vue";
import { FilterMatchMode } from 'primevue/api';
import { EmployeeService } from '@/services/EmployeeService';
import { EventService } from "@/services/EventService";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import Button from 'primevue/button';

const visible = ref(false);
const selectedData = ref(null);


function openDialog(data, isNowVisible) {
  selectedData.value = {...data}; 
  visible.value = isNowVisible;
  console.log("data:");
  console.log(data);
  console.log(selectedData.value);
}

const customers = ref();
const filteredEmployees = ref();
const events = ref();
const selectedEvent = ref();
const selectedEmployee = ref();

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'address': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    attachements: { value: null, matchMode: FilterMatchMode.IN },
    position: { value: null, matchMode: FilterMatchMode.EQUALS }, 
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS }
});

const attachements = ref([
    { name: 'Amy Elsner', image: 'amyelsner.png' },
    { name: 'Anna Fali', image: 'annafali.png' },
    { name: 'Asiya Javayant', image: 'asiyajavayant.png' },
    { name: 'Bernardo Dominic', image: 'bernardodominic.png' },
    { name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
    { name: 'Ioni Bowcher', image: 'ionibowcher.png' },
    { name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
    { name: 'Onyama Limba', image: 'onyamalimba.png' },
    { name: 'Stephen Shaw', image: 'stephenshaw.png' },
    { name: 'XuXue Feng', image: 'xuxuefeng.png' }
]);

const statuses = ref(['aktivní', 'nový', 'pozastavený', 'obnovený', 'nedokončený']);
const positions = ref(['crew', 'hosteska', 'asistent pořadatele']);
const loading = ref(true);
const checked = ref(false);

const relevantCountries = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'}
];

const selectCountryOptions = relevantCountries;

onMounted(() => {
    EmployeeService.getEmployeesAll().then((data) => {
      customers.value = getEmployees(data);
      loading.value = false;
      filteredEmployees.value =customers.value.filter(customer => customer.status !== 'zakázán');
    });
});

const getEmployees = (data) => {
    return [...(data || [])].map((d) => {
        d.date = new Date(d.date);

        return d;
    });
};

onMounted(() => {
      EventService.getEvents().then((data) => {
        events.value = getEvents(data); // Adjust this if you need to call a different method
        loading.value = false;
      });
    });

const getEvents = (data) => {
    return [...(data || [])].map((d) => {
        d.date = new Date(d.date);

        return d;
    });
};    

const formatDate = (value) => {
    if (!value) {
        // Handle the case where the value is null, undefined or an empty string
        return '';
    }
    const date = new Date(value);
    if (isNaN(date)) {
        // Handle the case where the value is not a valid date string
        return 'Invalid Date';
    }
    return date.toLocaleDateString('cs', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};
const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
const getSeverity = (status) => {
    switch (status) {
        case 'zakázán':
            return 'danger';

        case 'aktivní':
            return 'info';

        case 'nový':
            return 'success';

        case 'pozastavený':
            return 'warning';

        case 'obnovený':
            return null;
    }
}

onMounted(() => {

  const data = {
    columns: [
      {
        label: 'Jméno a příjmení',
        field: 'name'
      },
      'Datum narození',
      'Bydliště',
      'Další lokace',
    ],
    rows: [
      ["Tiger Nixon", "1991/04/25", "Kafkova 1380/15 Ostrava", "", ""],
      ["Garrett Winters", "Accountant", "Tokyo", "", ""],
      ["Ashton Cox", "Junior Technical Author", "San Francisco", "", ""],
    ],
  };
});

</script>