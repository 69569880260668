
<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn.vue";
import BaseInput from "@/components/BaseInput.vue";
import AddressComponent from '@/components/AddressComponent.vue'
import Toast from "primevue/toast";
import FileUpload from 'primevue/fileupload';
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import { useToast } from "primevue/usetoast";
import { reactive } from "vue";

import axiosClient from "../axios";
import { createLogger, useStore } from "vuex";
import { userStore } from "@/store/userStore";
import Checkbox from 'primevue/checkbox';

import { onMounted } from "vue";
import { removeCookies } from "@/utils/cookies";
import { useHasAddressErrpr } from "@/utils/errors";

const router = useRouter();
const authError = '';
const store = useStore();

let fileWasSelected = ref(false);
let fileWasUploaded = ref(false);

let fileWasUploadedError = ref(false);
let loading = ref(false);
let errors = ref({});

let fileUploadError = ref(false);
const fileUpload = ref(null);
let uploadedFile = reactive(null);

let addressErrors = ref({});
const success = ref('');
let programError = ref(false);
let backendError = ref(false);
let responseData = ref({});

// Initialize each field with ''
const user = reactive({
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  password_confirmation: '',
  phone_prefix: reactive(''),
  phone_number: '',
  address: reactive({
    country: null,
    countryCode: null,
    city: '',
    street: '',
    postal_code: '',
    house_number: '',
    orientation_number: null
  }),
  address_secondary: reactive({
    country: null,
    countryCode: null,
    city: '',
    street: '',
    postal_code: '',
    house_number: '',
    orientation_number: null
  }),
  personal_number: reactive({
    part_1: '',
    part_2: ''
  }),
  bank_account: reactive({
     prefix: '',
     account_number: '',
     bank_number: '',
     allow_sending_salary: ref(false),
  }),
  birth_date: '',
  birth_place: '',
  health_insurance_company: '',
  img_path: ''
});

const imgUploaded = ref(false);

function onCountryCodeChange(newCountryCode) {
  console.log("Here in onCountryCodeChange", newCountryCode);
  let newPrefix = '';
  if (newCountryCode === 'CZ') {
    console.log("CZ phone will be used.");
    newPrefix = '420';
  } else if (newCountryCode === 'SK') {
    console.log("SK phone will be used.");
    newPrefix = '421';
  }

  // Update phone number only if it does not already start with the new prefix
  if (newPrefix && !user.phone_prefix.startsWith(newPrefix)) {
    console.log("Updating phone number.");
    user.phone_prefix = newPrefix;
    console.log("Updated phone number: ", user.phone_prefix); // Check the updated value
  }
}; // Set immediate to false to not execute on the initial setup


const selectCountryOptions = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'}
];

const selectCountryOptionsSecondary = [
    {code: 'CZ', country_secondary: 'Česká republika'},
    {code: 'SK', country_secondary: 'Slovensko'}
];

const phonePrefixes = [
    { phone_country: 'CZ (+420)', code: '420' },
    { phone_country: 'SK (+421)', code: '421' }
];

const toast = useToast();

const onAdvancedUpload = (event) => {
    console.log("object:");
    console.log(object);
    console.log("file uploaded");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};

const onSelectedFiles = (event) => {
    console.log("event:");
    console.log(event);
    console.log("file selected");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    fileWasSelected.value = true;
}

async function customUpload(event) {
    console.log("event:");
    console.log(event);
    console.log("custom file uploaded");

    uploadedFile = event.files[0];

    console.log("uploadedFile:");
    console.log(uploadedFile);
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    // Create a new div element for the custom message
    const customMessage = document.createElement('div');
    customMessage.innerHTML = '<p style="color: green;">Soubor úspěšně nahrán.</p>'; // Replace with your actual message
    customMessage.classList.add('custom-message-class'); // Add any classes you need for styling

    // Locate the button bar where the buttons are located
    const buttonBar = document.querySelector('.p-fileupload-buttonbar');

    console.log("buttonBar:");
    console.log(buttonBar);

    // The upload itself...
    let formImageData = new FormData();

    console.log("responseData:");
    console.log(responseData);
    // let user_id = responseData['user']['id'];

    formImageData.append('image', uploadedFile);

    await axiosClient.get('/sanctum/csrf-cookie')
    .catch(error => {
        console.log("error in requesting csrf-cookie");
        console.log(error);
        programError.value = true;
    });
    console.log("File was uploaded. Trying to store it.");

    await axiosClient.post('/user/profile/img-upload', formImageData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    })
    .then(response => {
        console.log("response: /user/profile/img-upload");
        console.log(response);

        user.img_path = response.data.img_path;

        console.log("user.img_path:");
        console.log(user.img_path);

        imgUploaded.value = true;

        fileWasUploaded.value = true;
        fileWasUploadedError.value = false;

        // Now insert the custom message into the DOM, before the contentArea
        buttonBar.append("Nahrání bylo úspěšné.");
        fileUploadError.value = false;
    })
    .catch(error => {
        if(error.response) {
            if (error.response.status === 422) {
                backendError.value = true;
                console.log(error.response.data.errors);
                errors.value = error.response.data.errors;
            } else {
                programError.value = true;
                console.log("Other error than 422 catched:");
                console.log(error);
            }
            // TODO: Add separate error for the image upload. PRIORITY: A

        } else {
            console.log("Error without response, trying to log the error directly: \n"
            + error);
            programError.value = true;
        }
        fileUploadError.value = true;
    });

    // Reset the form and clear the uploaded file.
    fileUpload.value.clear();
    fileUpload.value.uploadedFileCount = 0;
}

async function register() {

    // Reset errors
    errors.value = {};

    // Validate phone prefix
    if (!user.phone_prefix) {
        errors.value.phone_prefix = ['Vyberte prosím předvolbu telefonu.'];
    }

    // Check if there are any errors
    if (Object.keys(errors.value).length > 0) {
        return; // Stop the submission if there are errors
    }

    if(fileWasSelected.value === true && fileWasUploaded.value === false) {
        fileWasUploadedError.value = true;
        return;
    }

    if (user.bank_account.allow_sending_salary) {
        if (!user.bank_account.account_number || !user.bank_account.bank_number) {
            errors.value.bank_account = ["Číslo účtu a číslo banky je povinné když je zaškrtnuto: Chci zasílat výplatu na účet."];
            return;
        }
    }

    const userData = userStore();

    errorMessages = []; // empty the error message

    store.commit('setToken', null);
    store.commit('setUser', null);
    console.log(user);
    console.log("Update 18:25");

    await axiosClient.get('/sanctum/csrf-cookie')
    .catch(error => {
        console.log("error in requesting csrf-cookie");
        console.log(error);
        programError.value = true;
    });
    console.log("CSRF cookie given.");

    await axiosClient.post('/register', user)
        .then(response => {
            console.log("Register response:");
            console.log(response);
            console.log(response.data);

            console.log("response.data.user.id:");

            responseData.value = response.data;
            console.log("responseData:");
            console.log(responseData);

            success.value = response.data.message; // TODO: Display on the login page the success message. PRIORITY: C
        })
        .catch(error => {
            if(error.response) {
                if (error.response.status === 422) {
                    backendError.value = true;
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                } else {
                    programError.value = true;
                    console.log("Other error than 422 catched:");
                    console.log(error.response);
                }
            } else {
                console.log("Error without response, trying to log the error directly: \n"
                    + error);
                console.log(error);
                programError.value = true;
            }
        });

    if (fileWasUploadedError.value === false && backendError.value === false && programError.value === false) {
        router.push({
            name: 'login',
            query: { success: "Registrace proběhla úspěšně." },
        });
        let token = '1234';
        userData.setUserToken(token);
    }

  // Resources: https://laraveldaily.com/post/laravel-vue-how-to-display-validation-errors
};

watch(() => user.bank_account.allow_sending_salary, (newValue) => {
  if (!newValue) {
    user.bank_account.prefix = '';
    user.bank_account.account_number = '';
    user.bank_account.bank_number = '';
  }
});

const isBankAccountRequired = computed(() => {
  return user.bank_account.allow_sending_salary;
});

let errorMessages = reactive([]);

const hasAnyError = computed(() => {
    console.log("hasAnyError:");
    console.log(errors.value);
    // debug
    if(errors.value) {
        if(errors.value['personal_number_part_1']) {
            console.log("errors.value['personal_number_part_1']:");
            console.log(errors.value['personal_number_part_1'][0]);
        }

        // this works for the nested attribute objects
        // personal_number.part_1
        if(errors.value['personal_number.part_1']) {
            console.log("errors.value.personal_number.part_1[0]:");
            console.log(errors.value['personal_number.part_1'][0]);
        }

        if(errors.value['personal_number_merged']) {
            console.log("errors.value.personal_number_merged[0]:");
            console.log(errors.value['personal_number_merged'][0]);
        }
    }

    console.log("App received following errors:");
    for (const [key, value] of Object.entries(errors.value)) {
        console.log("Received error:");
        console.log(`${key}: ${value}`);
        errorMessages.push(`${value}`);
    }

    return Object.keys(errors.value).length > 0;
});

const addressErrorMap = {
    'address.country': "Země původu",
    'address.city': "Město",
    'address.street': "Ulice",
    'address.house_number': "Číslo popisné",
    'address.orientation_number': "Číslo orientační",
    'address.postal_code': "Poštovní směrovací číslo",
    'address_secondary.country': "Země původu u kontaktní adresy",
    'address_secondary.countryCode': "Země původu u kontaktní adresy",
    'address_secondary.city': "Město u kontaktní adresy",
    'address_secondary.street': "Ulice u kontaktní adresy",
    'address_secondary.house_number': "Číslo popisné u kontaktní adresy",
    'address_secondary.orientation_number': "Číslo orientační",
    'address_secondary.postal_code': "Poštovní směrovací číslo u kontaktní adresy",
    // Add more mappings as needed
};

const formatErrorKey = (key) => {
    // Transform the key to match the error message format:
    // e.g., 'address.country' to 'Address.country'
    // and 'address_secondary.country' to 'Address secondary.country'
    return key.split('.').map((part, index) => {
        if (index === 0 && part === 'address') {
            return 'Address'; // Capitalize 'address'
        } else if (part === 'secondary') {
            return ' secondary'; // Add a space before 'secondary'
        } else {
            return part.replace(/_/g, ' '); // Replace underscores with spaces
        }
    }).join('.');
};

// TODO: This catches all errors, not only address. Fix this. PRIROITY: A
const hasAddressErrors = computed(() => {

    // debug
    console.log("hasAnyError:");
    console.log(errors.value);

    if(errors.value) {
        for (const [key, humanReadableKey] of Object.entries(addressErrorMap)) {
            if (errors.value[key]) {
                errors.value[key].forEach((error) => {
                    const formattedKey = formatErrorKey(key);
                    // Use a case-insensitive regular expression for replacement
                    const messageWithoutFieldName = error.replace(new RegExp(formattedKey, 'i'), humanReadableKey);
                    errorMessages.push(messageWithoutFieldName);
                });
            }
        }

        return errorMessages;
    }

    return false;
});

const hasPhoneError = computed(() => {
    if(errors.value?.phone?.[0]) {
        return "Telefonní číslo je ve špatném formátu.";
    }
    return false;
});

const hasBirthDateError = computed(() => {
    if(errors.value?.birth_date?.[0]) {
        return "Datum narození je ve špatném formátu.";
    }
    return false;
});

const hasBirthPlaceError = computed(() => {
    if(errors.value?.birth_place?.[0]) {
        return "Místo narození je ve špatném formátu.";
    }
    return false;
});

const hasHealthInsuranceCompanyError = computed(() => {
    if(errors.value?.birth_place?.[0]) {
        return "Zdravotní pojišťovna je ve špatném formátu.";
    }
    return false;
});

const hasPersonalNumberError = computed(() => {
    if(errors.value) {
        console.log("hasPersonalNumberError errors.value"); // TODO
        console.log(errors.value);
        if (errors.value['personal_number.part_1'] || errors.value['personal_number.part_2'] || errors.value['personal_number_merged']) {
            return "Rodné číslo je ve špatném formátu.";
        }
    }

    return false;
});

const hasImgUploadError = computed(() => {
    if(fileUploadError.value === true) {
        console.log("fileUploadError.value"); // TODO
        console.log(fileUploadError.value);
        return "Chyba při nahrání souboru.";
    }

    return false;
});

onMounted(() => {

    var container = document.getElementsByClassName("personal_number")[0];
    var container_2 = document.getElementsByClassName("personal_number")[1];

    console.log("container:");
    console.log(container);
    console.log("container_2:");
    console.log(container_2);

    container.onkeyup = function(e) {
        var maxLength = 6;
        var myLength = e.target.value.length;
        if (myLength >= maxLength) {
            console.log("Max length reached");
            container_2.focus();
        }
    }

    var container_bank_number_1 = document.getElementsByClassName("bank_number")[0];
    var container_bank_number_2 = document.getElementsByClassName("bank_number")[1];
    var container_bank_number_3 = document.getElementsByClassName("bank_number")[2];

    console.log("container_bank_number_1:");
    console.log(container_bank_number_1);
    console.log("container_bank_number_2:");
    console.log(container_bank_number_2);
    console.log("container_bank_number_3:");
    console.log(container_bank_number_3);

    container_bank_number_1.onkeyup = function(e) {
        var maxLength = 6;
        var myLength = e.target.value.length;
        if (myLength >= maxLength) {
            console.log("Max length reached");
            container_bank_number_2.focus();
        }
    }

    container_bank_number_2.onkeyup = function(e) {
        var maxLength = 10;
        var myLength = e.target.value.length;
        if (myLength >= maxLength) {
            console.log("Max length reached");
            container_bank_number_3.focus();
        }
    }

});

function passwordVisible() {
  var x = document.getElementById("password");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

function passwordConfirmVisible() {
  var x = document.getElementById("password_confirm");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

const pasteAndSplitBankNumber = async () => {
    event.preventDefault(); // Prevent default behavior
    event.target.blur(); // Remove focus from the button
    try {
        const text = await navigator.clipboard.readText();
        const parts = text.split(/[-/]/);

        if (parts.length === 2 || parts.length === 3) {
            if (parts.length === 3) {
                user.bank_account.prefix = parts[0];
                user.bank_account.account_number = parts[1];
                user.bank_account.bank_number = parts[2];
            } else {
                user.bank_account.prefix = '';
                user.bank_account.account_number = parts[0];
                user.bank_account.bank_number = parts[1];
            }
        } else {
            alert('Neplatný formát čísla účtu. Použijte formát: prefix-účet/banka nebo účet/banka');
        }
    } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
        alert('Nepodařilo se přečíst obsah schránky. Zkontrolujte, zda máte povolený přístup ke schránce.');
    }
};


function setInputFilter(textbox, inputFilter, errMsg) {
  [ "input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout" ].forEach(function(event) {
    textbox.addEventListener(event, function(e) {
      if (inputFilter(this.value)) {
        // Accepted value.
        if ([ "keydown", "mousedown", "focusout" ].indexOf(e.type) >= 0) {
          this.classList.remove("input-error");
          this.setCustomValidity("");
        }

        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      }
      else if (this.hasOwnProperty("oldValue")) {
        // Rejected value: restore the previous one.
        this.classList.add("input-error");
        this.setCustomValidity(errMsg);
        this.reportValidity();
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
      else {
        // Rejected value: nothing to restore.
        this.value = "";
      }
    });
  });
}

onMounted(() => {
  setInputFilter(document.querySelector("input[id='postal_code_primary']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 99999);
  }, "Zadejte prosím platnou PSČ");

  setInputFilter(document.querySelector("input[id='postal_code_secondary']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 99999);
  }, "Zadejte prosím platnou PSČ");

  setInputFilter(document.querySelector("input[id='personal_number_part_1']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 999999);
  }, "Zadejte prosím platnou první část rodného čísla.");

  setInputFilter(document.querySelector("input[id='personal_number_part_2']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 9999);
  }, "Zadejte prosím platnou druhou část rodného čísla.");

  setInputFilter(document.querySelector("input[id='prefix']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 999999);
  }, "Zadejte prosím platné předčíslí.");

  setInputFilter(document.querySelector("input[id='account_number']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 9999999999);
  }, "Zadejte prosím platné číslo účtu.");

  setInputFilter(document.querySelector("input[id='bank_number']"), function(value) {
    return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 9999);
  }, "Zadejte prosím platné číslo banky.");
});

</script>

<template>
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Registrace</h2>
    </div>
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" action="#" @submit.prevent="register">
                <div>
                    <BaseInput
                    label="Jméno*"
                    name="firstname"
                    v-model="user.firstname"
                    placeholder="Jméno"
                    required
                    />
                    <span class="text-red-600" v-if="errors?.firstname">Zadané jméno obsahuje chybu: {{ errors.firstname[0] }}</span>
                </div>

                <div>
                    <BaseInput
                    label="Příjmení*"
                    name="lastname"
                    v-model="user.lastname"
                    placeholder="Příjmení"
                    required
                    />
                    <span class="text-red-600" v-if="errors?.lastname">Zadané jméno obsahuje chybu: {{ errors.lastname[0] }}</span>
                </div>
                <div>
                    <BaseInput
                    label="E-mailová adresa*"
                    name="email"
                    type="email"
                    v-model="user.email"
                    autocomplete="email"
                    required
                    />
                    <span class="text-red-600" v-if="errors?.email">Zadaný e-mail obsahuje chybu: {{ errors.email[0] }}</span>
                </div>
                <div>
                    <BaseInput
                    label="Heslo*"
                    name="password"
                    type="password"
                    :minlength=8
                    v-model="user.password"
                    required
                    />
                    <span class="text-red-600" v-if="errors?.password">Zadané heslo obsahuje chybu: {{ errors.password[0] }}</span>
                    <div>
                        Zobraz heslo
                        <input
                        type="checkbox"
                        label="Náhled hesla"
                        @click="passwordVisible()"
                        />
                    </div>
                </div>
                <div>
                    <BaseInput
                    label="Potvrzení hesla*"
                    name="password_confirm"
                    type="password"
                    :minlength=8
                    v-model="user.password_confirmation"
                    required
                    />
                    <div>
                        Zobraz heslo
                        <input
                        type="checkbox"
                        label="Náhled hesla"
                        @click="passwordConfirmVisible()"
                        />
                    </div>
                </div>

                <section id="primary-address">
                    <h2>Adresa trvalého bydliště</h2>
                    <div>
                        <label for="country" class="block text-sm font-medium leading-6text-gray-900">Národnost*</label>
                        <div class="mt-2">
                            <v-select
                                id="country-select"
                                v-model="user.address.countryCode"
                                :options="selectCountryOptions"
                                :reduce="country => country.code"
                                placeholder="Národnost"
                                label="country"
                                @update:modelValue="onCountryCodeChange"
                            />
                            <input id="country" name="country" type="hidden" style="opacity: 0; width: 0; float: left" v-model="user.address.country">
                        </div>
                    </div>

                    <div>
                        <div class="mt-2">
                            <input id="country_code" name="country_code" type="hidden" autocomplete="country_code"
                                placeholder="Kód země"
                                v-model="user.address.countryCode"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                            ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                            disabled/>
                            <AddressComponent :object_address="user.address" suffix="primary" :isRequired="true"></AddressComponent>
                        </div>
                        <!-- notirce the required attribute has no effect if disabled -->
                    </div>
                </section>

                <Accordion>
                    <AccordionTab header="Kontaktní adresa (nepovinné)">
                        <section id="secondary-address-block">
                            <div>
                                <label for="country_secondary" class="block text-sm font-medium le_secondaryading-6 text-gray-900">Národnost</label>
                                <div class="mt-2">
                                    <v-select
                                        id="country-secondary-select"
                                        v-model="user.address_secondary.countryCode"
                                        :options="selectCountryOptionsSecondary"
                                        :reduce="country_secondary => country_secondary.code"
                                        label="country_secondary"
                                    />
                                    <input id="country_secondary" type="hidden" name="country_secondary" v-model="user.address_secondary.country">
                                </div>
                            </div>
                            <div>
                                <label for="country_code_secondary" class="block text-sm font-medium leading-6 text-gray-900">Kód země</label>
                                <div class="mt-2">
                                    <input id="country_code_secondary" name="country_code_secondary" type="text" autocomplete="country_code_secondary"
                                        placeholder="Kód země"
                                        v-model="user.address_secondary.countryCode"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                                    ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                    focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled/>
                                </div>
                            </div>
                            <AddressComponent :object_address="user.address_secondary" suffix="secondary" :isRequired="false"></AddressComponent>
                        </section>
                    </AccordionTab>
                </Accordion>

                <h2>Kontakt</h2>
                <div v-if="hasPhoneError" class="text-red-600">
                    Telefoní číslo není zadáno v platném formátu.
                </div>

                <div class="block">
                    <div class="flex">

                        <select v-model="user.phone_prefix"
                        id="phone_prefix"
                        class="p-inputmask p-inputtext p-component block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 inline"
                        required
                        >
                            <option value="" disabled selected hidden style="color: rgb(156 163 175)">Vyberte</option>
                            <option v-for="prefix in phonePrefixes" :key="prefix.code" :value="prefix.code">
                                {{ prefix.phone_country }}
                            </option>
                        </select>
                        <div id="phone_prefix_error" v-if="errors?.phone_prefix" class="text-red-600">
                            Zadaný telefon obsahuje chybu:
                            {{ errors.phone_prefix[0] }}
                        </div>

                        <InputMask
                        id="phone_number"
                        label="Telefonní číslo*"
                        name="phone_number"
                        v-model="user.phone_number"
                        :value="user.phone_number"
                        mask="999 999 999"
                        placeholder="999 999 999"
                        pattern="[0-9]{3} [0-9]{3} [0-9]{3}"
                        class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 inline"
                        required
                        />
                    </div>
                </div>
                <small class="block">Zadejte ve tvaru: 420 111 222 333 (s mezerami)</small>

                <h2>Osobní údaje</h2>

                <div v-if="hasBirthDateError" class="text-red-600">Zadané datum narození obsahuje chybu: {{ hasBirthDateError }}</div>
                <div>
                    <BaseInput
                        label="Datum narození*"
                        name="birth_date"
                        type="date"
                        v-model="user.birth_date"
                        placeholder="Datum narození"
                        required
                    />
                </div>

                <div v-if="hasBirthPlaceError" class="text-red-600">Zadané místo narození obsahuje chybu: {{ hasBirthPlaceError }}</div>
                <div>
                    <BaseInput
                        label="Místo narození*"
                        name="birth_place"
                        type="text"
                        v-model="user.birth_place"
                        placeholder="Místo narození"
                        required
                    />
                </div>

                <div v-if="hasHealthInsuranceCompanyError" class="text-red-600">Zadaná popjišťovna je zadána špatně: {{ hasHealthInsuranceCompanyError }}</div>
                <div>
                    <BaseInput
                        label="Zdravotní pojišťovna (vypište název)*"
                        name="health_insurance_company"
                        type="text"
                        v-model="user.health_insurance_company"
                        placeholder="Zdravotní pojišťovna"
                        required
                    />
                </div>

                <div v-if="hasPersonalNumberError" class="text-red-600">Rodné číslo obsahuje chybu: {{ hasPersonalNumberError }}</div>
                <div>
                    <label for="personal_number_part_1" class="block text-sm font-medium leading-6 text-gray-900">
                        Rodné číslo*
                    </label>
                    <div class="flex">
                        <BaseInput
                            name="personal_number_part_1"
                            type="text"
                            v-model="user.personal_number.part_1"
                            placeholder="Rodné číslo: 1. část"
                            pattern="[0-9]{6}"
                            :minlength="4"
                            maxlength="6"
                            inputClass="personal_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                        />
                        <span class="slash-between-inputs">/</span>
                        <BaseInput
                            name="personal_number_part_2"
                            type="text"
                            v-model="user.personal_number.part_2"
                            placeholder="Koncovka"
                            pattern="\d{3}|\d{4}"
                            :minlength="3"
                            maxlength="4"
                            inputClass="personal_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                    <small>Zadejte pouze číslovky, bez lomítka.</small>
                </div>

                <div>
                    <label for="bank_account" class="block text-sm font-medium leading-6 text-gray-900">
                        Bankovní číslo
                    </label>
                    <button @click="pasteAndSplitBankNumber" class="mb-2 px-4 py-2 rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Vložit číslo účtu
                    </button>
                    <br>
                    <small><p>Klikněte pro vložení po zkopírování vašeho čísla účtu.</p><p>V případě, že vám nefunguje, vyplňte prosím číslo účtu ručně.</p></small>
                    <div class="flex">
                        <BaseInput
                        name="prefix"
                        type="text"
                        v-model="user.bank_account.prefix"
                        placeholder="Předčíslí"
                        pattern="[0-9]+"
                        :minlength="2"
                        maxlength="6"
                        inputClass="bank_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :required=false
                        />
                        <span class="slash-between-inputs">/</span>
                        <BaseInput
                        name="account_number"
                        type="text"
                        v-model="user.bank_account.account_number"
                        placeholder="Číslo účtu"
                        pattern="[0-9]+"
                        maxlength="10"
                        inputClass="bank_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :required="isBankAccountRequired"
                        />
                        <span class="slash-between-inputs">/</span>
                        <BaseInput
                        name="bank_number"
                        type="text"
                        v-model="user.bank_account.bank_number"
                        placeholder="Číslo banky"
                        pattern="[0-9]{4}"
                        maxlength="4"
                        :minlength="4"
                        inputClass="bank_number block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        :required="isBankAccountRequired"
                        />
                    </div>
                    <ul v-if="hasAddressErrors.length" class="text-red-600">
                        Zadané pole obsahuje chyby
                        <li v-for="(error, index) in hasAddressErrors" :key="index">
                            {{ error }}
                        </li>
                    </ul>
                    <div class="flex items-center mb-4">
                        <Checkbox name="allow_sending_salary" v-model="user.bank_account.allow_sending_salary" id="allow_sending_salary" :binary="true"/>
                        <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Chci zasílat výplatu na účet</label>
                    </div>
                    <ul v-if="errors.bank_account" class="text-red-600">
                        Zadané pole obsahuje chyby
                        <li v-for="(error, index) in errors.bank_account" :key="index">
                            {{ error }}
                        </li>
                    </ul>
                    <small>Zadejte pouze číslovky, bez lomítka.</small>
                </div>

                <div class="" id="file-upload-section">
                    <label for="personal_number" class="block text-sm font-medium leading-6 text-gray-900">
                        Nahrejte fotografii
                    </label>
                    <div class="mt-2">
                        <div class="card">
                            <Toast />
                            <FileUpload
                                ref="fileUpload"
                                @upload="onAdvancedUpload($event)"
                                :multiple="false"
                                name="demo[]"
                                accept="image/*"
                                :maxFileSize="10000000"
                                cancelLabel="Zrušit"
                                chooseLabel="Vybrat"
                                uploadLabel="Nahrát"
                                invalidFileSizeMessage="Velikost souboru nesmí být větší než 10 MB."
                                invalidFileTypeMessage="Špatný formát souboru. Soubor musí být obrázek formátu: jpg, jpeg, png nebo tif."
                                customUpload
                                @uploader="customUpload"
                                fileLimit="1"
                                @select="onSelectedFiles($event)"
                                @error="onUploadError($event)"
                                data-cy="file-upload"
                                >
                                <template #empty>
                                    <p>Přetáhni sem soubor pro nahrání. Povoleny jsou typické soubory obrázků (např. png, jpeg).</p>
                                </template>
                            </FileUpload>
                            <p id="file-upload-instruction">Po vybrání souboru klikněte na <b>Nahrát</b>.</p>
                        </div>
                    </div>
                    <ul v-if="hasImgUploadError" class="text-red-600">
                        Nahrání souboru obsahuje chyby:
                        {{ hasImgUploadError }}
                    </ul>
                </div>

                <div v-if="authError" class="error-message" style="color: red">
                    {{ authError }}
                </div>

                <div>
                    <BaseBtn
                        data-cy="register-submit-button"
                        type="submit"
                        text="Registrovat"
                    />
                    <div id="program-error" v-if="programError" class="error-message" style="color: red">
                        Systém je dočasně nedostupný, vyzkoušejte prosím později.
                    </div>
                    <div data-cy="has-any-error" v-if="hasAnyError" class="text-red-600">
                        Prosím, opravte chyby ve formuláři.
                        {{ hasAnyError }}
                    </div>
                    <div id="backend-error" data-cy="has-any-backend-error" v-if="hasAnyError" class="text-red-600">
                        Byla nalezena chyba při zpracování odeslaných dat.
                    </div>
                    <div id="file-upload-error" v-if="fileWasUploadedError" class="error-message" style="color: red">
                        Nahrejte prosím fotografii stiskem na tlačítko "Nahrát".
                    </div>
                    <div id="success" v-if="success" class="text-green-600">
                        Odeslání formuláře bylo úspěšné.
                    </div>
                </div>

            </form>

            <p class="mt-10 text-center text-sm text-gray-500">
                Jste již zaregistrováni?
                {{ ' ' }}
                <router-link :to="{name: 'login'}"
                             class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                    Přihlásit se
                </router-link>
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>
