
<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import BaseInput from "@/components/BaseInput.vue";
import { useToast } from "primevue/usetoast";
import { reactive } from "vue";
import axiosClient from "../axios";
import { createLogger, useStore } from "vuex";
import Checkbox from 'primevue/checkbox';
import EventsManagement from "@/views/EventsManagement.vue";
import FileUpload from "primevue/fileupload";
import Toast from "primevue/toast";
import BaseBtn from "@/components/BaseBtn.vue";

const router = useRouter();
const store = useStore();

let fileWasSelected = ref(false);
let fileWasUploaded = ref(false);

let fileWasUploadedError = ref(false);
let errors = ref({});

let fileUploadError = ref(false);
const fileUpload = ref(null);
let uploadedFile = reactive(null);

const success = ref('');
let programError = ref(false);
let backendError = ref(false);
let responseData = ref({});
const selectedEvent = ref(null);

// Initialize each field with ''
const offer = ref({
    eventName: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    baseReward: 0,
    hours: 0,
    otherCosts: 0,
    description: '',
    numberOfPeople: 0,
    position: '',
    workFrom: '',
    workTo: '',
    vat: 21,
    billingRateBeforeDiscount: 0,
    billingRateAfterDiscount: 0,
    validUntil: '',
    clientResponsibilities: {
        freeWcAccess: ref(false),
        freeShowerAccess: ref(false),
        freeFood: ref(false),
        parking: ref(false),
        securedPlace: ref(false),
    },
    settings: {
        timeBasedInvoice: ref(false),
        advanceInvoice: ref(false),
        advanceInvoiceOnAmount: ref(false),
        cancelFee25: ref(false),
        cancelFee50: ref(false),
        cancelFee100: ref(false),
        noCancelFeeOnForceMajeure: ref(false),
    },
    supplierCompany: '',
    supplierIc: '',
    supplierResponsiblePerson: '',
    clientCompany: '',
    clientIC: '',
    clientResponsiblePerson: '',
    clientEmail: '',
    supplierSignature: null,
    clientSignature: null,
});

const totalHours = computed(() => {
    // Calculate total hours based on workFrom and workTo
    return 0; // Placeholder
});

const billingRateBeforeDiscount = computed(() => {
    // Calculate billing rate before discount
    return 0; // Placeholder
});

const billingRateAfterDiscount = computed(() => {
    // Calculate billing rate after discount
    return 0; // Placeholder
});

const total = computed(() => {
    // Calculate total
    return 0; // Placeholder
});

const totalPriceWithoutVAT = computed(() => {
    // Calculate total price without VAT
    return 0; // Placeholder
});

const totalPriceWithVAT = computed(() => {
    // Calculate total price with VAT
    return 0; // Placeholder
});

function updateSupplierSignature(event) {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            offer.value.supplierSignature = e.target.result;
        };
        reader.readAsDataURL(file);
    }
}

function updateClientSignature(event) {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            offer.value.clientSignature = e.target.result;
        };
        reader.readAsDataURL(file);
    }
}

function onCountryCodeChange(newCountryCode) {
    console.log("Here in onCountryCodeChange", newCountryCode);
    let newPrefix = '';
    if (newCountryCode === 'CZ') {
        console.log("CZ phone will be used.");
        newPrefix = '420';
    } else if (newCountryCode === 'SK') {
        console.log("SK phone will be used.");
        newPrefix = '421';
    }

    // Update phone number only if it does not already start with the new prefix
    if (newPrefix && !offer.phone_prefix.startsWith(newPrefix)) {
        console.log("Updating phone number.");
        offer.phone_prefix = newPrefix;
        console.log("Updated phone number: ", offer.phone_prefix); // Check the updated value
    }
} // Set immediate to false to not execute on the initial setup


const selectCountryOptions = [
    {code: 'CZ', country: 'Česká republika'},
    {code: 'SK', country: 'Slovensko'}
];

const selectCountryOptionsSecondary = [
    {code: 'CZ', country_secondary: 'Česká republika'},
    {code: 'SK', country_secondary: 'Slovensko'}
];

const phonePrefixes = [
    { phone_country: 'CZ (+420)', code: '420' },
    { phone_country: 'SK (+421)', code: '421' }
];

const toast = useToast();

const onAdvancedUpload = (event) => {
    console.log("object:");
    console.log(object);
    console.log("file uploaded");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};

const onSelectedFiles = (event) => {
    console.log("event:");
    console.log(event);
    console.log("file selected");
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    fileWasSelected.value = true;
}

async function customUpload(event) {
    console.log("event:");
    console.log(event);
    console.log("custom file uploaded");

    uploadedFile = event.files[0];

    console.log("uploadedFile:");
    console.log(uploadedFile);
    //toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });

    // Create a new div element for the custom message
    const customMessage = document.createElement('div');
    customMessage.innerHTML = '<p style="color: green;">Soubor úspěšně nahrán.</p>'; // Replace with your actual message
    customMessage.classList.add('custom-message-class'); // Add any classes you need for styling

    // Locate the button bar where the buttons are located
    const buttonBar = document.querySelector('.p-fileupload-buttonbar');

    console.log("buttonBar:");
    console.log(buttonBar);

    // The upload itself...
    let formImageData = new FormData();

    console.log("responseData:");
    console.log(responseData);
    // let offer_id = responseData['offer']['id'];

    formImageData.append('image', uploadedFile);

    await axiosClient.get('/sanctum/csrf-cookie')
        .catch(error => {
            console.log("error in requesting csrf-cookie");
            console.log(error);
            programError.value = true;
        });
    console.log("File was uploaded. Trying to store it.");

    await axiosClient.post('/offer/profile/img-upload', formImageData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    })
        .then(response => {
            console.log("response: /offer/profile/img-upload");
            console.log(response);

            offer.img_path = response.data.img_path;

            console.log("offer.img_path:");
            console.log(offer.img_path);

            imgUploaded.value = true;

            fileWasUploaded.value = true;
            fileWasUploadedError.value = false;

            // Now insert the custom message into the DOM, before the contentArea
            buttonBar.append("Nahrání bylo úspěšné.");
            fileUploadError.value = false;
        })
        .catch(error => {
            if(error.response) {
                if (error.response.status === 422) {
                    backendError.value = true;
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                } else {
                    programError.value = true;
                    console.log("Other error than 422 catched:");
                    console.log(error);
                }
                // TODO: Add separate error for the image upload. PRIORITY: A

            } else {
                console.log("Error without response, trying to log the error directly: \n"
                    + error);
                programError.value = true;
            }
            fileUploadError.value = true;
        });

    // Reset the form and clear the uploaded file.
    fileUpload.value.clear();
    fileUpload.value.uploadedFileCount = 0;
}

async function register() {

    // Reset errors
    errors.value = {};

    // Validate phone prefix
    if (!offer.phone_prefix) {
        errors.value.phone_prefix = ['Vyberte prosím předvolbu telefonu.'];
    }

    // Check if there are any errors
    if (Object.keys(errors.value).length > 0) {
        return; // Stop the submission if there are errors
    }

    if(fileWasSelected.value === true && fileWasUploaded.value === false) {
        fileWasUploadedError.value = true;
        return;
    }

    if (offer.bank_account.allow_sending_salary) {
        if (!offer.bank_account.account_number || !offer.bank_account.bank_number) {
            errors.value.bank_account = ["Číslo účtu a číslo banky je povinné když je zaškrtnuto: Chci zasílat výplatu na účet."];
            return;
        }
    }

    errorMessages = []; // empty the error message

    store.commit('setToken', null);
    store.commit('setUser', null);
    console.log(offer);
    console.log("Update 18:25");

    await axiosClient.get('/sanctum/csrf-cookie')
        .catch(error => {
            console.log("error in requesting csrf-cookie");
            console.log(error);
            programError.value = true;
        });
    console.log("CSRF cookie given.");

    await axiosClient.post('/register', offer)
        .then(response => {
            console.log("Register response:");
            console.log(response);
            console.log(response.data);

            console.log("response.data.offer.id:");

            responseData.value = response.data;
            console.log("responseData:");
            console.log(responseData);

            success.value = response.data.message; // TODO: Display on the login page the success message. PRIORITY: C
        })
        .catch(error => {
            if(error.response) {
                if (error.response.status === 422) {
                    backendError.value = true;
                    console.log(error.response.data.errors);
                    errors.value = error.response.data.errors;
                } else {
                    programError.value = true;
                    console.log("Other error than 422 catched:");
                    console.log(error.response);
                }
            } else {
                console.log("Error without response, trying to log the error directly: \n"
                    + error);
                console.log(error);
                programError.value = true;
            }
        });

    if (fileWasUploadedError.value === false && backendError.value === false && programError.value === false) {
        router.push({
            name: 'login',
            query: { success: "Registrace proběhla úspěšně." },
        });
        let token = '1234';
    }

    // Resources: https://laraveldaily.com/post/laravel-vue-how-to-display-validation-errors
}

let errorMessages = reactive([]);

const hasAnyError = computed(() => {
    console.log("hasAnyError:");
    console.log(errors.value);
    // debug
    if(errors.value) {
        if(errors.value['personal_number_part_1']) {
            console.log("errors.value['personal_number_part_1']:");
            console.log(errors.value['personal_number_part_1'][0]);
        }

        // this works for the nested attribute objects
        // personal_number.part_1
        if(errors.value['personal_number.part_1']) {
            console.log("errors.value.personal_number.part_1[0]:");
            console.log(errors.value['personal_number.part_1'][0]);
        }

        if(errors.value['personal_number_merged']) {
            console.log("errors.value.personal_number_merged[0]:");
            console.log(errors.value['personal_number_merged'][0]);
        }
    }

    console.log("App received following errors:");
    for (const [key, value] of Object.entries(errors.value)) {
        console.log("Received error:");
        console.log(`${key}: ${value}`);
        errorMessages.push(`${value}`);
    }

    return Object.keys(errors.value).length > 0;
});

const addressErrorMap = {
    'address.country': "Země původu",
    'address.city': "Město",
    'address.street': "Ulice",
    'address.house_number': "Číslo popisné",
    'address.orientation_number': "Číslo orientační",
    'address.postal_code': "Poštovní směrovací číslo",
    'address_secondary.country': "Země původu u kontaktní adresy",
    'address_secondary.countryCode': "Země původu u kontaktní adresy",
    'address_secondary.city': "Město u kontaktní adresy",
    'address_secondary.street': "Ulice u kontaktní adresy",
    'address_secondary.house_number': "Číslo popisné u kontaktní adresy",
    'address_secondary.orientation_number': "Číslo orientační",
    'address_secondary.postal_code': "Poštovní směrovací číslo u kontaktní adresy",
    // Add more mappings as needed
};

const formatErrorKey = (key) => {
    // Transform the key to match the error message format:
    // e.g., 'address.country' to 'Address.country'
    // and 'address_secondary.country' to 'Address secondary.country'
    return key.split('.').map((part, index) => {
        if (index === 0 && part === 'address') {
            return 'Address'; // Capitalize 'address'
        } else if (part === 'secondary') {
            return ' secondary'; // Add a space before 'secondary'
        } else {
            return part.replace(/_/g, ' '); // Replace underscores with spaces
        }
    }).join('.');
};

// TODO: This catches all errors, not only address. Fix this. PRIROITY: A
const hasAddressErrors = computed(() => {

    // debug
    console.log("hasAnyError:");
    console.log(errors.value);

    if(errors.value) {
        for (const [key, humanReadableKey] of Object.entries(addressErrorMap)) {
            if (errors.value[key]) {
                errors.value[key].forEach((error) => {
                    const formattedKey = formatErrorKey(key);
                    // Use a case-insensitive regular expression for replacement
                    const messageWithoutFieldName = error.replace(new RegExp(formattedKey, 'i'), humanReadableKey);
                    errorMessages.push(messageWithoutFieldName);
                });
            }
        }

        return errorMessages;
    }

    return false;
});

const hasPhoneError = computed(() => {
    if(errors.value?.phone?.[0]) {
        return "Telefonní číslo je ve špatném formátu.";
    }
    return false;
});

const hasBirthDateError = computed(() => {
    if(errors.value?.birth_date?.[0]) {
        return "Datum narození je ve špatném formátu.";
    }
    return false;
});

const hasBirthPlaceError = computed(() => {
    if(errors.value?.birth_place?.[0]) {
        return "Místo narození je ve špatném formátu.";
    }
    return false;
});

const hasHealthInsuranceCompanyError = computed(() => {
    if(errors.value?.birth_place?.[0]) {
        return "Zdravotní pojišťovna je ve špatném formátu.";
    }
    return false;
});

const hasPersonalNumberError = computed(() => {
    if(errors.value) {
        console.log("hasPersonalNumberError errors.value"); // TODO
        console.log(errors.value);
        if (errors.value['personal_number.part_1'] || errors.value['personal_number.part_2'] || errors.value['personal_number_merged']) {
            return "Rodné číslo je ve špatném formátu.";
        }
    }

    return false;
});

const hasImgUploadError = computed(() => {
    if(fileUploadError.value === true) {
        console.log("fileUploadError.value"); // TODO
        console.log(fileUploadError.value);
        return "Chyba při nahrání souboru.";
    }

    return false;
});

function passwordVisible() {
    let x = document.getElementById("password");
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
}

function passwordConfirmVisible() {
    let x = document.getElementById("password_confirm");
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
}

const pasteAndSplitBankNumber = async () => {
    event.preventDefault(); // Prevent default behavior
    event.target.blur(); // Remove focus from the button
    try {
        const text = await navigator.clipboard.readText();
        const parts = text.split(/[-/]/);

        if (parts.length === 2 || parts.length === 3) {
            if (parts.length === 3) {
                offer.bank_account.prefix = parts[0];
                offer.bank_account.account_number = parts[1];
                offer.bank_account.bank_number = parts[2];
            } else {
                offer.bank_account.prefix = '';
                offer.bank_account.account_number = parts[0];
                offer.bank_account.bank_number = parts[1];
            }
        } else {
            alert('Neplatný formát čísla účtu. Použijte formát: prefix-účet/banka nebo účet/banka');
        }
    } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
        alert('Nepodařilo se přečíst obsah schránky. Zkontrolujte, zda máte povolený přístup ke schránce.');
    }
};


function setInputFilter(textbox, inputFilter, errMsg) {
    [ "input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout" ].forEach(function(event) {
        textbox.addEventListener(event, function(e) {
            if (inputFilter(this.value)) {
                // Accepted value.
                if ([ "keydown", "mousedown", "focusout" ].indexOf(e.type) >= 0) {
                    this.classList.remove("input-error");
                    this.setCustomValidity("");
                }

                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            }
            else if (this.hasOwnProperty("oldValue")) {
                // Rejected value: restore the previous one.
                this.classList.add("input-error");
                this.setCustomValidity(errMsg);
                this.reportValidity();
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
            else {
                // Rejected value: nothing to restore.
                this.value = "";
            }
        });
    });
}


const getResponsibilityText = (key) => {
    const texts = {
        freeWcAccess: 'Bezplatný přístup k WC',
        freeShowerAccess: 'Bezplatný přístup ke sprchám',
        freeFood: 'Stravu (nad 6h) + pitný režim.',
        parking: 'Vyhrazený hlídaný prostor pro zaparkování v areálu konání akce.',
        securedPlace: 'Hlídaný prostor zázemí po celou dobu.'
    };
    return texts[key] || key;
};

const getSettingText = (key) => {
    const texts = {
        timeBasedInvoice: 'Konečná částka pro fakturaci bude vypočtena na základě odpracovaného času, avšak každý časový úsek je minimální 5 hodin.',
        advanceInvoice: 'Před nástupem zakázky bude vystavena zálohová faktura ve výši 90% plánované částky (kalkulace) s DPH. Tato částka musí být zaplacena nejpozději v den splatnosti.',
        advanceInvoiceOnAmount: 'Zálohová faktura bude vystavena na částku:',
        cancelFee25: 'V případě zrušení zakázky budou účtovány strorno poplatky - 25%  nejpozději však týden před nástupem zakázky.',
        cancelFee50: '50% storno poplatku bude uplatněno když zakázku klient zruší méně než 7 dnů před nástupem zakázky.',
        cancelFee100: 'Storno poplatek je účtován z celkové výše záloh uhrazených  před realizací zakázky.',
        noCancelFeeOnForceMajeure: 'Storno poplatek nebude vyžadován v případě zrušení zakázky z důvodu vyšší moci.'
    };
    return texts[key] || key;
};

// Helper functions for formatting
const formatDateRange = (dateFrom, dateTo) => {
    const from = new Date(dateFrom).toLocaleDateString('cs-CZ');
    const to = new Date(dateTo).toLocaleDateString('cs-CZ');
    return dateFrom === dateTo ? from : `${from} - ${to}`;
};

const formatTimeRange = (timeFrom, timeTo) => {
    return `${timeFrom.slice(0, 5)} - ${timeTo.slice(0, 5)}`;
};

const formatAddress = (address) => {
    return `${address.street} ${address.house_number}${address.orientation_number ? '/' + address.orientation_number : ''}, ${address.postal_code} ${address.city}, ${address.country}`;
};
</script>

<template>
    <div class="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
        <div class="mt-10">
            <h1 class="text-3xl font-bold tracking-tight text-gray-900">Nabídka</h1>
            <EventsManagement
                :selectionEnabled="true"
                v-model:selectedEventId="selectedEvent"
            ></EventsManagement>
            <div class="summary">
                <h2 class="text-2xl font-bold mb-4">Vybraná akce:</h2>
                <div v-if="selectedEvent" class="bg-gray-100 p-4 rounded-lg">
                    <p><strong>Název:</strong> {{ selectedEvent.name }}</p>
                    <p><strong>Datum:</strong> {{ formatDateRange(selectedEvent.date_from, selectedEvent.date_to) }}</p>
                    <p><strong>Čas:</strong> {{ formatTimeRange(selectedEvent.time_from, selectedEvent.time_to) }}</p>
                    <p><strong>Odměna:</strong> {{ selectedEvent.salary }} Kč</p>
                    <p><strong>Typ:</strong> {{ selectedEvent.type || 'Neuvedeno' }}</p>
                    <p><strong>Status:</strong> {{ selectedEvent.status }}</p>
                    <p><strong>Ověřeno:</strong> {{ selectedEvent.verified ? 'Ano' : 'Ne' }}</p>
                    <div class="mt-2">
                        <strong>Adresa:</strong>
                        <p v-if="selectedEvent.event_address">
                            {{ formatAddress(selectedEvent.event_address) }}
                        </p>
                        <p v-else>Adresa neuvedena</p>
                    </div>
                </div>
                <p v-else>Žádná akce není vybrána</p>

                <p>Součet nákladů: {{ totalCosts }} Kč</p>
                <p>Součet hodin pro všechny osoby: {{ totalHours }}</p>
            </div>

            <div class="details">
                <BaseInput label="Počet hodin" type="number" v-model="offer.hours" />
                <BaseInput label="Ostatní náklady" type="number" v-model="offer.otherCosts" />
                <BaseInput label="Popis" v-model="offer.description" />
                <BaseInput label="Počet osob" type="number" v-model="offer.numberOfPeople" />
                <BaseInput label="Pozice" v-model="offer.position" />
                <BaseInput label="Práce od" type="datetime-local" v-model="offer.workFrom" />
                <BaseInput label="Práce do" type="datetime-local" v-model="offer.workTo" />
                <BaseInput label="Fakturační sazba před slevou" type="number" v-model="offer.billingRateBeforeDiscount" />
                <BaseInput label="Fakturační sazba po slevě" type="number" v-model="offer.billingRateAfterDiscount" />
            </div>

            <div class="totals">
                <p>Celkem: {{ total }} Kč</p>
                <BaseInput label="DPH (%)" type="number" v-model="offer.vat" />
                <p>Celkový počet hodin: {{ totalHours }} hodin</p>
                <p>Cena celkem bez DPH: {{ totalPriceWithoutVAT }} Kč</p>
                <p>Cena celkem s DPH: {{ totalPriceWithVAT }} Kč</p>
            </div>

            <BaseInput label="Nabídka je za stávajících podmínek platná do" type="date" v-model="offer.validUntil" />
            <div class="parties">
                <div class="supplier">
                    <h3>Dodavatel:</h3>
                    <BaseInput label="Název společnosti" v-model="offer.supplierCompany" />
                    <BaseInput label="IČ společnosti" v-model="offer.supplierIc" />
                    <BaseInput label="Zodpovědná osoba" v-model="offer.supplierResponsiblePerson" />
                </div>

                <div class="client">
                    <h3>Objednatel:</h3>
                    <BaseInput label="Název společnosti" v-model="offer.clientCompany" />
                    <BaseInput label="IČ společnosti" v-model="offer.clientIC" />
                    <BaseInput label="Zodpovědná osoba" v-model="offer.clientResponsiblePerson" />
                    <BaseInput label="E-mail objednatele" type="email" v-model="offer.clientEmail" />
                </div>
            </div>

            <div class="signatures">
                <div class="supplier-signature">
                    <h4 class="mt-2 text-lg font-bold">Podpis dodavatele:</h4>
                    <div class="mt-2">
                        <div class="card">
                            <Toast />
                            <FileUpload
                                ref="fileUpload"
                                @upload="onAdvancedUpload($event)"
                                :multiple="false"
                                name="demo[]"
                                accept="image/*"
                                :maxFileSize="10000000"
                                cancelLabel="Zrušit"
                                chooseLabel="Vybrat"
                                uploadLabel="Nahrát"
                                invalidFileSizeMessage="Velikost souboru nesmí být větší než 10 MB."
                                invalidFileTypeMessage="Špatný formát souboru. Soubor musí být obrázek formátu: jpg, jpeg, png nebo tif."
                                customUpload
                                @uploader="customUpload"
                                fileLimit="1"
                                @select="onSelectedFiles($event)"
                                @error="onUploadError($event)"
                                data-cy="file-upload"
                            >
                                <template #empty>
                                    <p>Přetáhni sem soubor pro nahrání. Povoleny jsou typické soubory obrázků (např. png, jpeg).</p>
                                </template>
                            </FileUpload>
                            <p id="file-upload-instruction">Po vybrání souboru klikněte na <b>Nahrát</b>.</p>
                        </div>
                    </div>
                </div>

                <div class="client-signature">
                    <h4 class="mt-2 text-lg font-bold">Podpis objednatele:</h4>
                    <div class="mt-2">
                        <div class="card">
                            <Toast />
                            <FileUpload
                                ref="fileUpload"
                                @upload="onAdvancedUpload($event)"
                                :multiple="false"
                                name="demo[]"
                                accept="image/*"
                                :maxFileSize="10000000"
                                cancelLabel="Zrušit"
                                chooseLabel="Vybrat"
                                uploadLabel="Nahrát"
                                invalidFileSizeMessage="Velikost souboru nesmí být větší než 10 MB."
                                invalidFileTypeMessage="Špatný formát souboru. Soubor musí být obrázek formátu: jpg, jpeg, png nebo tif."
                                customUpload
                                @uploader="customUpload"
                                fileLimit="1"
                                @select="onSelectedFiles($event)"
                                @error="onUploadError($event)"
                                data-cy="file-upload"
                            >
                                <template #empty>
                                    <p>Přetáhni sem soubor pro nahrání. Povoleny jsou typické soubory obrázků (např. png, jpeg).</p>
                                </template>
                            </FileUpload>
                            <p id="file-upload-instruction">Po vybrání souboru klikněte na <b>Nahrát</b>.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="responsibilities">
                <h3 class="mb-2 mt-2 text-lg font-bold">Objednatel zajišťuje:</h3>
                <div v-for="(key, index) in Object.keys(offer.clientResponsibilities)" :key="index">
                    <Checkbox :inputId="'responsibility-' + index" v-model="offer.clientResponsibilities[key]" :binary="true" />
                    <label :for="'responsibility-' + index">{{ getResponsibilityText(key) }}</label>
                </div>
            </div>

            <h3 class="mb-2 mt-2 text-lg font-bold">Nastavení:</h3>
            <div v-for="(key, index) in Object.keys(offer.settings)" :key="index">
                <Checkbox :inputId="'setting-' + index" v-model="offer.settings[key]" :binary="true" />
                <label :for="'setting-' + index">{{ getSettingText(key) }}</label>
                <input v-if="key === 'advanceInvoiceOnAmount'" type="number" v-model="offer.advanceInvoiceAmount" placeholder="Částka v Kč">
            </div>
        </div>
        <div>
            <BaseBtn
                class="mt-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                type="submit"
                text="Uložit novou nabídku"
            />
        </div>
    </div>
</template>
