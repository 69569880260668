import axios from "axios";
import router from "./router/index.js";
import { useGlobalErrorStore } from "./store/globalErrorStore.js";
import store from "./store/index.js";


axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true,
  withXSRFToken: true,
  
});

axiosClient.interceptors.request.use(config => {
  let token = sessionStorage.getItem('TOKEN');
  config.headers.Authorization = `Bearer ${token}`
  return config;
});

axiosClient.interceptors.response.use(
  response => response,
  error => {
    console.error('Axios error:', error);
    return Promise.reject(error);
  }
);

function handleAxiosError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
      url: error.config.url,
    };
  } else if (error.request) {
    // The request was made but no response was received
    return {
      message: "No response received from the server",
      url: error.config.url,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      message: error.message,
      url: error.config?.url,
    };
  }
}

axiosClient.interceptors.response.use(response => {
  return response;
}, error => {
  const globalError = useGlobalErrorStore();

  if (error.response) {
    if (error.response.status === 401) {
      store.commit('setToken', null);
      router.push({name: 'login'});
    } else if (error.response.status === 422) {
      // Pass the validation errors to the component
      return Promise.reject(error.response.data);
    }
  } else {
    const readableError = handleAxiosError(error);
    console.log("Axios error:", readableError);
    globalError.setError();
    sessionStorage.setItem('global_program_error', true);
  }

  throw error;
});

export default axiosClient;